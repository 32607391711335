import React, { ReactElement } from "react";
import { FluidSharpImageFile } from "@ymcansw-camping/common";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import SchoolsLayout from "../../components/schools-layout";

type IntroData = {
  readonly personalGrowthImage: FluidSharpImageFile;
  readonly communityImage: FluidSharpImageFile;
  readonly natureImage: FluidSharpImageFile;
};

export default function IntroJourneyPage(): ReactElement {
  const { personalGrowthImage, communityImage, natureImage } =
    useStaticQuery<IntroData>(graphql`
      {
        personalGrowthImage: file(
          relativePath: { eq: "img/school-personal-growth.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        communityImage: file(relativePath: { eq: "img/school-community.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        natureImage: file(relativePath: { eq: "img/school-nature.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        #      pageFile: file(relativePath: { eq: "pages/school-intro.md" }) {
        #        childMarkdownRemark {
        #          frontmatter {
        #            activitiesTableTitle
        #            activities {
        #              title
        #              learningOutcomes
        #            }
        #          }
        #        }
        #      }
      }
    `);

  return (
    <SchoolsLayout
      meta={{
        title: "Intro to Journey Based Programs",
        description:
          "This program introduces new skills required to progress to journey based programs. Using site based facilities at Camp Yarramundi and the natural bushland of Deeimba, students will learn basic camp skills including packing, meal preparation and cooking on Trangias.",
      }}
    >
      <div className="content-block">
        <h2>Intro to Journey based programs</h2>
        <p>
          This program introduces new skills required to progress to journey
          based programs. Using site based facilities at Camp Yarramundi and the
          natural bushland of Deeimba, students will learn basic camp skills
          including packing, meal preparation and cooking on Trangias. Students
          will also learn essential journey based skills, including expedition,
          basic rope skills and navigation, with a strong focus on connecting to
          the natural world we are working in. Multiple activities can be run
          concurrently in any order to accommodate multiple groups.
        </p>
      </div>

      <div className="content-block icon-circle-container">
        <h3 className="h5">Potential Learning Outcomes</h3>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <div className="icon-circle-block text-center">
              <Img
                fluid={personalGrowthImage.childImageSharp.fluid}
                alt="Personal Growth"
              />
              <h4 className="title">Personal Growth</h4>
              <p>
                Build resilience
                <br />
                Make healthy choices
                <br />
                Develop independence
                <br />
                Develop new activity skills
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="icon-circle-block text-center">
              <Img
                fluid={communityImage.childImageSharp.fluid}
                alt="Sense of Community"
              />
              <h4 className="title">Sense of Community</h4>
              <p>
                Connect with others
                <br />
                Work as a team
                <br />
                Solve problems together
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="icon-circle-block text-center">
              <Img
                fluid={natureImage.childImageSharp.fluid}
                alt="Natural World"
              />
              <h4 className="title">Connect with Natural World</h4>
              <p>Appreciate the natural world</p>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block">
        <h3 className="h5">
          Intro to Journey based programs including sample program
        </h3>
        <p className="h6 mt2">Suitable for:</p>
        <ul>
          <li>Primary School – Year 6</li>
          <li>Secondary/High School – Year 7 to Year 12</li>
          <li>Universities</li>
          <li>International Schools</li>
        </ul>
      </div>
      {/* <SchoolActivitiesTable */}
      {/*  title={frontmatter.activitiesTableTitle} */}
      {/*  activities={frontmatter.activities} */}
      {/* /> */}
    </SchoolsLayout>
  );
}
